<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <div class="page-title-div">编辑资讯</div>
      <div class="news-base-div">
        <div>发布人：{{newsInfo.creator}}</div>
        <div>发布时间：{{newsInfo.createTime}}</div>
        <div>浏览量：{{newsInfo.views}}</div>
      </div>
      <validation-observer ref="form">
        <div class="item-div item-common-div">
          <div class="item-title-div">文章标题：</div>
          <div>
            <validation-provider rules="required|minmax:1,20" v-slot="{ errors }" name="newsName">
              <label>
                <input type="text" v-model="newsInfo.title" maxlength="20" placeholder="请输入文章标题">
              </label>
              <label class="tips-label err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div item-common-div">
          <div class="item-title-div">文章摘要：</div>
          <div>
            <validation-provider rules="required|minmax:1,60" v-slot="{ errors }" name="newsRemark">
              <label>
                <input type="text" v-model="newsInfo.remark" maxlength="60" placeholder="请输入文章摘要">
              </label>
              <label class="tips-label err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div item-common-div">
          <div class="item-title-div">发布地区：</div>
          <div>
            <label>
              <select class="province-select" v-model="newsInfo.province">
                <option value="">全国</option>
                <option v-for="item in provinces" :value="item.id">{{item.name}}</option>
              </select>
            </label>
          </div>
        </div>
        <div class="item-div item-common-div">
          <div class="item-title-div">文章图片：</div>
          <div class="news-img-div">
            <div class="img-div">
              <img ref="newsImg" :src="`${$imgBaseURL}/${newsInfo.img}`" alt="">
            </div>
            <label class="upload-label" @change="onAddImg($event)">
              选择图片
              <input class="cursor-el" type="file" />
            </label>
          </div>
        </div>
        <div class="item-div">
          <div class="item-title-div">文章内容：</div>
          <div class="tinymce-div">
            <tinymce class="tinymce" v-model="newsInfo.content"/>
          </div>
        </div>
      </validation-observer>
      <div class="submit-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <input type="button" value="保存" @click="onSubmit">
      </div>
    </div>
  </div>
</template>

<script>
import Tinymce from "../../components/Tinymce"
import { getProvinces, getNewsDetails, postEditNews } from "../../common/api";

export default {
  name: "NewsAdd",
  components: { Tinymce },
  data() {
    return {
      provinces: [],
      newsInfo: {
        id: "",
        title: "",
        remark: "",
        province: "",
        img: "",
        content: "",
        creator: "",
        views: 0,
        createTime: ""
      },
      newsImg: null,
      tipMsg: ""
    }
  },
  created() {
    this.doGetProvinces();
    this.init();
  },
  methods: {
    init() {
      getNewsDetails({id: this.$route.query.id})
        .then(data => {
          if(!Object.prototype.hasOwnProperty.call(data, "province")){
            data.province = "";
          }
          this.newsInfo = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.setTipsMsg(error.data.msg);
          } else {
            this.setTipsMsg("查询数据失败，请稍后重试");
          }
        });
    },
    doGetProvinces() {
      getProvinces()
          .then(data => {
            this.provinces = data;
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("查询省份信息失败，请稍后重试");
            }
          });
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.$refs.newsImg.src = reader.result;
        that.newsImg = img;
        event.target.value = null;
      };
      reader.onerror = function() {
        that.newsImg = null;
        that.$refs.newsImg.src = null;
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onSubmit() {
      if(this.newsInfo.content.length < 1){
        this.setTipsMsg("请输入文章内容");
        return;
      }

      this.$refs.form.validate().then(res => {
        if(res){
          let formData = new FormData();
          formData.append("id", this.newsInfo.id);
          formData.append("title", this.newsInfo.title);
          formData.append("remark", this.newsInfo.remark);
          formData.append("province", this.newsInfo.province);
          formData.append("content", this.newsInfo.content);
          if(this.newsImg != null){
            formData.append("file", this.newsImg)
          }
          postEditNews(formData)
            .then(() => {
              this.setTipsMsg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.setTipsMsg(error.data.msg);
              } else {
                this.setTipsMsg("操作失败");
              }
            });
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
    .page-title-div {
      font-size: 17px;
      font-weight: bold;
      padding-bottom: 10px;
      border-bottom: 1px solid #cccccc;
      margin-bottom: 10px;
    }
    .news-base-div {
      display: inline-flex;
      margin-bottom: 10px;
      div {
        margin-right: 20px;
      }
    }
    .item-div {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      .item-title-div {
        width: 75px;
      }
      .province-select {
        height: 35px;
        border: 1px solid #dcdcdc;
      }
      .news-img-div {
        display: flex;
        align-items: center;
        .img-div {
          width: 100px;
          height: 60px;
          margin-right: 10px;
          img{
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      input {
        width: 500px;
        height: 33px;
        padding: 0 5px;
        border: 1px solid #dcdcdc;
      }
      .tinymce-div {
        width: 100%;
        .tinymce {
          width: 100%;
        }
      }
      .tips-label {
        margin-left: 5px;
      }
    }
    .item-common-div {
      align-items: center;
    }
    .submit-div {
      text-align: center;
      margin-top: 15px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>